<script>
import { ValidationObserver } from 'vee-validate'
import { useRouter } from 'vue-router/composables'
import BaseAsidePage from '/~/components/base/aside-page/base-aside-page.vue'
import BaseButton from '/~/components/base/button/base-button.vue'
import BaseInput from '/~/components/base/input/base-input.vue'
import { useBackendValidation } from '/~/composables/backend-validation'
import { useForm } from '/~/composables/base/use-form'
import { useProvider } from '/~/composables/provider'
import { useUser } from '/~/composables/user'

export default {
  name: 'drawer-change-password',
  components: {
    BaseInput,
    BaseButton,
    BaseAsidePage,
    ValidationObserver,
  },
  setup() {
    const { updateUserPassword } = useUser()
    const { backendErrors, processBackendErrors } = useBackendValidation()
    const { validationObserverRef } = useForm()
    const router = useRouter()
    const { isPasswordEditable } = useProvider()

    if (!isPasswordEditable.value) {
      router.push({ hash: '' })
    }

    return {
      updateUserPassword,
      backendErrors,
      processBackendErrors,
      validationObserverRef,
    }
  },
  data() {
    return {
      form: {
        currentPassword: '',
        newPassword: '',
        confirmNewPassword: '',
      },
      submitting: false,
    }
  },
  methods: {
    async onSubmitForm() {
      this.submitting = true
      this.backendErrors = {}

      try {
        await this.updateUserPassword({
          ...this.form,
          confirmPassword: this.form.newPassword,
        })
        this.$router.back()
        this.form.currentPassword = ''
        this.form.newPassword = ''
      } catch (errors) {
        this.processBackendErrors({ errors })
      } finally {
        this.submitting = false
      }
    },
  },
}
</script>

<template>
  <base-aside-page title="Change password">
    <validation-observer
      v-slot="{ handleSubmit }"
      ref="validationObserverRef"
      slim
    >
      <form
        class="relative flex h-full flex-col"
        @submit.prevent="handleSubmit(onSubmitForm)"
      >
        <div class="relative grow overflow-y-auto">
          <base-input
            v-model="form.currentPassword"
            :validation="{
              rules: 'required',
              name: 'Current Password',
              vid: 'current_password',
            }"
            :error="backendErrors.currentPassword"
            :disabled="submitting"
            label="Current password"
            required
            type="password"
          />
          <base-input
            ref="new_password"
            v-model="form.newPassword"
            :validation="{
              rules: 'required|password',
              name: 'New Password',
              vid: 'new_password',
            }"
            :disabled="submitting"
            label="New password"
            required
            type="password"
          />
          <base-input
            ref="confirm_new_password"
            v-model="form.confirmNewPassword"
            :validation="{
              rules: 'required|confirmed:@new_password,New Password',
              name: 'Confirm New Password',
              vid: 'confirm_new_password',
            }"
            :disabled="submitting"
            label="Confirm new password"
            required
            type="password"
          />
        </div>
        <base-button
          type="submit"
          :disabled="submitting"
          full-width
          class="mt-5 shrink-0"
        >
          {{ submitting ? 'Saving...' : 'Save' }}
        </base-button>
      </form>
    </validation-observer>
  </base-aside-page>
</template>
